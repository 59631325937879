<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import Select from '../forms/Select.vue'
import { ref } from 'vue'
import { useDataStore } from '@/stores/data'
import { combineFilterWithDefaultStructure } from './helpers'
import Filter from '@/components/filters/Filter.vue'
import QueryString from 'qs'
import Input from '../forms/Input.vue'
import { buildQuery, fetchReservationsNonPaginated, index } from '@/services/apiService'
import { ModelName, ReservationStatus } from '@/enums'
import { toast } from 'vue3-toastify'
import { captureException } from '@sentry/vue'

const emit = defineEmits(['filter', 'loaded'])

const userStore = useUserStore()
if (!window.localStorage.getItem('slgr_dashboard_filter_form')) {
  {
    window.localStorage.setItem('slgr_dashboard_filter_form', '{"user_id":[' + userStore.user?.id + '],"location_id":[],"date":["",""],"status":"active","rangetype":"plus_60"}')
  }
}

console.log(QueryString.stringify({ user_id: [1, 2, 3] }))

const dataStore = useDataStore()
const locations = ref<App.Models.Location[]>([])
const users = ref<App.Models.User[]>([])
const events = ref<App.Models.Event[]>([])
const reservations = ref<App.Models.Reservation[]>([])
Promise.all([
  dataStore.getLocations().then((response) => (locations.value = response)),
  dataStore.getUsers().then((response) => (users.value = response)),
  dataStore.getEvents().then((response) => (events.value = [...events.value, ...response])),
  fetchReservationsNonPaginated(buildQuery({ status: ReservationStatus.Active }, [], [], ['id', 'reservation_name'])).then(
    (response) => (reservations.value = [...reservations.value, ...response]),
  ),
])
  .then(() => emit('loaded'))
  .catch((err) => {
    toast.error('Failed to load dashboard')
    console.error(err)
    captureException(err)
  })

const default_structure = JSON.stringify({
  user_id: userStore.user ? [userStore.user.id] : [],
  location_id: [],
  external_event_id: [],
  id: [],
  date: [null, null],
  status: 'active',
  rangetype: 'plus_60',
})

function fetchMissingEvents() {
  let idsToFetch = [] as number[]
  for (const externalId of filters.value.external_event_id) {
    if (!events.value.some((event) => event.external_id === externalId)) {
      idsToFetch.push(externalId)
    }
  }
  idsToFetch = idsToFetch.filter((id) => typeof id === 'number' && id > 0)
  if (idsToFetch.length === 0) return
  index<App.Models.Event>(ModelName.Event, buildQuery({ external_id: idsToFetch }))
    .then((response) => {
      events.value = [...events.value, ...response]
    })
    .catch((err) => {
      toast.error('Failed to load event')
      console.error(err)
      captureException(err)
    })

  console.log(',missing2', idsToFetch)
}

function fetchMissingReservations() {
  let idsToFetch = [] as number[]
  for (const reservationId of filters.value.id) {
    if (!reservations.value.some((reservation) => reservation.id === reservationId)) {
      idsToFetch.push(reservationId)
    }
  }
  idsToFetch = idsToFetch.filter((id) => typeof id === 'number' && id > 0)
  if (idsToFetch.length === 0) return
  console.log(',missing', idsToFetch)
  fetchReservationsNonPaginated(buildQuery({ id: idsToFetch }, [], [], ['id', 'reservation_name']))
    .then((response) => {
      reservations.value = [...reservations.value, ...response]
    })
    .catch((err) => {
      toast.error('Failed to load reservation')
      console.error(err)
      captureException(err)
    })
}

const filters = ref(JSON.parse(default_structure))
const lastApplied = ref<string | null>(null)

function applyFilters() {
  if (JSON.stringify(filters.value) !== lastApplied.value) {
    fetchMissingEvents()
    fetchMissingReservations()

    convertRangeType(filters.value)

    lastApplied.value = JSON.stringify(filters.value)
    emit('filter', filters.value)
  }
}
function setFilters(f) {
  console.log('Setting filters', f, JSON.parse(default_structure)) // Please leave this here for debugging
  filters.value = combineFilterWithDefaultStructure(f, JSON.parse(default_structure))
}
function resetFilters() {
  filters.value = JSON.parse(default_structure)
}

function convertRangeType(filters) {
  let startDate, endDate
  if (filters.rangetype === 'plus_60') {
    startDate = new Date()
    startDate.setDate(startDate.getDate() - 7)

    endDate = new Date()
    endDate.setDate(endDate.getDate() + 60)
  } else if (filters.rangetype === 'plus_180') {
    startDate = new Date()
    startDate.setDate(startDate.getDate() - 7)

    endDate = new Date()
    endDate.setDate(endDate.getDate() + 180)
  }
  if (startDate && endDate) {
    filters.date = [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]]
  }
}
</script>

<template>
  <Filter
    filter-name="slgr2_dashboard_filter_form"
    :filters="filters"
    :auto-apply="true"
    :save-filter-button="false"
    :enable-saving="true"
    :reset-button="true"
    class-list="p-4 mt-6 mb-6 rounded-md bg-elevation1"
    @apply="applyFilters"
    @set="setFilters"
    @reset="resetFilters"
  >
    <div class="col-span-3">
      <Select
        v-model="filters.user_id"
        :items="
          users.map((user: App.Models.User) => ({
            value: user.id,
            label: user.preview_name ?? user.name,
          }))
        "
        label="User"
        formname="User"
        placeholder="Search ..."
        searchable
        multiple
      />
    </div>

    <div class="col-span-3">
      <Select
        v-model="filters.location_id"
        :items="
          locations.map((location: App.Models.Location) => ({
            value: location.id,
            label: location.name,
          }))
        "
        label="Location"
        formname="location"
        placeholder="Search ..."
        searchable
        multiple
      />
    </div>
    <div class="col-span-3">
      <Select
        v-model="filters.status"
        :items="[
          { value: 'all', label: 'All' },
          { value: 'returned', label: 'Returned' },
          { value: 'active', label: 'Active' },
        ]"
        label="Status"
        formname="status"
      />
    </div>

    <div class="col-span-3">
      <Select
        v-model="filters.external_event_id"
        :items="
          events.map((event: App.Models.Event) => ({
            value: event.external_id,
            label: event.name,
            desc: event.external_id + ' ' + event.description,
          }))
        "
        label="Event"
        formname="Event"
        placeholder="Search ..."
        searchable
        multiple
      />
    </div>

    <div class="col-span-3">
      <Select
        v-model="filters.id"
        :items="
          reservations.map((reservation: App.Models.Reservation) => ({
            value: reservation.id,
            label: reservation.reservation_name,
            desc: reservation.id,
          }))
        "
        label="Reservation"
        formname="Reservation"
        placeholder="Search ..."
        searchable
        multiple
      />
    </div>

    <div
      class="col-span-1 sm:col-span-6 lg:col-span-9 xl:col-span-9"
      :class="{
        'grid grid-cols-1 gap-y-2 gap-x-3 lg:gap-x-6 md:grid-cols-2  lg:grid-cols-3': filters.rangetype === 'custom',
      }"
    >
      <div class="md:col-span-2 lg:col-span-1">
        <Select
          v-model="filters.rangetype"
          :items="[
            { value: 'custom', label: 'Custom date range' },
            { value: 'plus_60', label: 'Now +60 days' },
            { value: 'plus_180', label: 'Now +180 days' },
          ]"
          label="Date filter"
          formname="status"
          class=""
        />
      </div>
      <div class="">
        <Input label="Date from" v-if="filters.rangetype === 'custom'" type="date" v-model="filters.date[0]" />
      </div>
      <div class="">
        <Input label="Date to" v-if="filters.rangetype === 'custom'" type="date" v-model="filters.date[1]" />
      </div>
    </div>
    <div class="col-span-3 -mt-3 -mb-5 text-sm italic text-gray-400 sm:col-span-6">By choosing event or reservation, all other filters will be ignored.</div>
  </Filter>
</template>
