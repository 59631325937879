<script setup lang="ts">
import MenuComponent from '@/components/menu/MenuComponent.vue'
import { SettingsKeys } from '@/enums'
import router from '@/router'
import { fetchCurrentUser } from '@/services/apiService'
import { useDialogStore } from '@/stores/dialog'
import { useSettingsStore } from '@/stores/settings'
import { useUserStore } from '@/stores/user'
import { onBeforeUnmount } from 'vue'
import { ref, watch } from 'vue'
import { RouterView } from 'vue-router'
import { toast } from 'vue3-toastify'
import { captureException } from '@sentry/vue'
import * as Sentry from '@sentry/vue'

const dialogStore = useDialogStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const user = ref(userStore.user)
const loaded = ref(!!user.value)
const collapsed = ref(false)
const loginExpiryCountdown = ref(false)
const sessionRefreshInterval = ref(null as number | null)
const isDev = import.meta.env.VITE_DEV === 'true'
watch(
  user,
  () => {
    if (user.value) {
      loaded.value = true
      setLoginExpiration()
      setSessionRefresh()
      Sentry.setUser({
        id: user.value.id,
        email: user.value.email,
        username: user.value.name,
      })
    }
  },
  { immediate: true },
)

if (!settingsStore.getSettings(SettingsKeys.DefaultLocation)) {
  toast.warn('Please set default location in settings')
}

onBeforeUnmount(() => {
  if (sessionRefreshInterval.value !== null) {
    clearInterval(sessionRefreshInterval.value)
  }
})

function setSessionRefresh() {
  if (sessionRefreshInterval.value !== null) {
    return
  }
  sessionRefreshInterval.value = setInterval(() => {
    if (user.value) {
      fetchCurrentUser()
        .then(() => {})
        .catch((err) => {
          if (err?.status === 401) {
            console.error(err)
            toast.error('Session expired')
            captureException(err)
            if (sessionRefreshInterval.value !== null) {
              clearInterval(sessionRefreshInterval.value)
            }
            router.push({ name: 'Logout' })
          }
        })
    }
  }, 3600000)
}

function setLoginExpiration() {
  const expiration = window.localStorage.getItem('slgr2_login_expiration')
  console.log(expiration)
  if (expiration && typeof expiration === 'string') {
    const loginExpiry = new Date(expiration)
    if (user.value && !isNaN(loginExpiry) && !loginExpiryCountdown.value) {
      console.log('setting login expiry check', loginExpiry)
      if (new Date() > loginExpiry) {
        // already expired, logout
        router.push({ name: 'Logout' })
        if (sessionRefreshInterval.value !== null) {
          clearInterval(sessionRefreshInterval.value)
        }
        return
      }
      loginExpiry.setMinutes(loginExpiry.getMinutes() - 5)
      setLoginExpirationCheck(loginExpiry)
      loginExpiryCountdown.value = true
    }
  }
}

function setLoginExpirationCheck(loginExpiry: Date) {
  if (!user.value) {
    // not logged in, so do not set any timer
    loginExpiryCountdown.value = false
    dialogStore.cancelConfirm()
    return
  }
  const interval = setInterval(() => {
    if (new Date() > loginExpiry) {
      // user must answer within 5 mintues, otherwise will be logged out
      const idleCheck = setTimeout(
        () => {
          dialogStore.resolveConfirm()
          if (sessionRefreshInterval.value !== null) {
            clearInterval(sessionRefreshInterval.value)
          }
          router.push({ name: 'Logout' })
        },
        5 * 60 * 1000,
      )
      dialogStore.showConfirm({
        title: 'Your session is about to expiry',
        description: 'You have been logged for too long, so do you want to stay logged in for another hour?',
        additionalText: 'Your unsaved work may be deleted if you logout. You have to decide within 5 minutes, otherwise, you will be logged out automatically.',
        onConfirm: () => {
          clearTimeout(idleCheck)
          const d = new Date()
          d.setMinutes(d.getMinutes() + 60)
          window.localStorage.setItem('slgr2_login_expiration', d.toISOString())
          d.setMinutes(d.getMinutes() - 5)
          setLoginExpirationCheck(d)
          loginExpiryCountdown.value = true
        },
        buttonConfirmText: 'Keep me logged in',
        buttonCancelText: 'Logout',
        onCancel: () => {},
        onStrictCancel: () => {
          clearTimeout(idleCheck)
          if (sessionRefreshInterval.value !== null) {
            clearInterval(sessionRefreshInterval.value)
          }
          router.push({ name: 'Logout' })
        },
        preventCloseOnOutsideClick: true,
      })
      loginExpiryCountdown.value = false
      clearInterval(interval)
    }
  }, 1000)
}

function getLocalUrl() {
  return `http://192.168.0.142:5173${window.location.pathname}${window.location.search}`
}
</script>

<template>
  <div class="z-10 hidden overflow-x-hidden transition-all desktop-menu lg:fixed lg:inset-y-0 lg:flex lg:flex-col" :class="[collapsed ? 'lg:w-14' : 'lg:w-64']">
    <MenuComponent @collapsed="(val) => (collapsed = val)" />
  </div>
  <div id="main_menu" class="w-full menu lg:hidden">
    <MenuComponent @collapsed="(val) => (collapsed = val)" />
  </div>
  <div class="flex flex-col flex-1 transition-all" :class="[collapsed ? 'lg:pl-14' : 'lg:pl-64']">
    <div class="min-h-empty">
      <div class="mb-4" v-if="!isDev && (user?.id === 9 || user?.id === 98)">
        <div class="fixed left-0 text-center bg-red-500" style="top: 0; right: 0; z-index: 1000">PRODUCTION - [<a :href="getLocalUrl()" target="_blank">Open local]</a></div>
      </div>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active {
  transition: opacity 0.3s ease;
}
.fade-leave-active {
  transition: opacity 0.15s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
