import { fetchCurrentUser, loginUsingOauth } from '@/services/apiService'
import { useUserStore } from '@/stores/user'
import { toast } from 'vue3-toastify'
import { captureException } from '@sentry/vue'

function cleanUrlFromCode(fullPath: string) {
  // Parse the fullPath into components
  const url = new URL(fullPath, window.location.origin)

  // Remove the 'code' parameter if it exists
  url.searchParams.delete('code')

  // Reconstruct a clean fullPath without 'code'
  return url.pathname + url.search + url.hash
}

export function authGuard(to, from, next) {
  const userStore = useUserStore()

  if (to.meta.authRequired && !userStore.getUser()) {
    // Redirect to login if not authenticated
    // we should load user on every F5, the request will be authenticated using cookie. If fails, we redirect to login

    const code = to?.query?.code ?? from?.query?.code ?? null
    const isEventApiLogin = to?.query?.event_api_login ?? from?.query?.event_api_login ?? null
    console.log('to', to)
    console.log('from', from)
    if (code && !isEventApiLogin && to.path === '/') {
      loginUsingOauth({ code })
        .then((response) => {
          if (response.success && response.user) {
            userStore.setUser(response.user)

            if (localStorage.getItem('slgr2_login_remember_me') !== 'true') {
              const d = new Date()
              d.setMinutes(d.getMinutes() + 60)
              localStorage.setItem('slgr2_login_expiration', d.toISOString())
            } else {
              localStorage.removeItem('slgr2_login_expiration')
            }

            if (response.user?.is_blocked === true) {
              next({ path: '/logout' })
            } else if (to.meta.adminOnly && response.user?.role !== 'gear_master') {
              toast.error('You are not authorized to access this page')
              next({ path: '/' })
            } else {
              next()
            }
          } else {
            toast.error(response.message)
            console.error(response)
            next({ path: '/login', query: { redirect: cleanUrlFromCode(to.fullPath) } })
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(err)
          captureException(err)
          console.error(err)
          next({ path: '/login', query: { redirect: cleanUrlFromCode(to.fullPath) } })
        })
    } else {
      fetchCurrentUser()
        .then((user) => {
          userStore.setUser(user)
          if (user?.is_blocked === true) {
            next({ path: '/logout' })
          } else if (to.meta.adminOnly && user?.role !== 'gear_master') {
            toast.error('You are not authorized to access this page')
            next({ path: '/' })
          } else {
            next()
          }
        })
        .catch((err) => {
          console.log(err)
          next({ path: '/login', query: { redirect: cleanUrlFromCode(to.fullPath) } })
        })
    }
  } else {
    // Proceed to the route
    next()
  }
}
