import { ref } from 'vue'
import { defineStore } from 'pinia'

export interface Dialog {
  title: string
  description: string
  additionalText?: string
  buttonText?: string
  onClose?: () => void
}

export interface ConfirmDialog<T> {
  title: string
  description: string
  additionalText?: string
  buttonConfirmText?: string
  buttonCancelText?: string
  type?: string
  data?: T
  onConfirm: (data: T | undefined) => void
  onClose?: () => void
  onCancel?: () => void
  onStrictCancel?: () => void
  additionalButtons?: { text: string; onClick: () => void; variant: string }[]
  preventCloseOnOutsideClick?: boolean
}

/**
 * Include only more constant data that dont change so often. Items is better to fetch everytime as they can change very often
 */
export const useDialogStore = defineStore('dialog', () => {
  const dialogs = ref<Dialog[]>([])
  const confirmDialogs = ref<ConfirmDialog<object>[]>([])

  function showDialog(dialog: Dialog): void {
    dialogs.value.push(dialog)
  }

  function showConfirm(dialog: ConfirmDialog<object>) {
    confirmDialogs.value.push(dialog)
  }

  function resolveDialog(): void {
    if (dialogs.value[0] && dialogs.value[0].onClose) {
      dialogs.value[0].onClose()
    }
    dialogs.value.shift()
  }

  function resolveConfirm(): void {
    confirmDialogs.value[0].onConfirm(confirmDialogs.value[0].data)
    if (confirmDialogs.value[0] && confirmDialogs.value[0].onClose) {
      confirmDialogs.value[0].onClose()
    }
    confirmDialogs.value.shift()
  }

  function cancelConfirm(clickedButton: boolean = false): void {
    if (confirmDialogs.value[0] && confirmDialogs.value[0].onClose) {
      confirmDialogs.value[0].onClose()
    }
    if (confirmDialogs.value[0] && confirmDialogs.value[0].onCancel) {
      confirmDialogs.value[0].onCancel()
    }
    if (clickedButton && confirmDialogs.value[0] && confirmDialogs.value[0].onStrictCancel) {
      confirmDialogs.value[0].onStrictCancel()
    }
    confirmDialogs.value.shift()
  }

  return {
    dialogs,
    confirmDialogs,
    showConfirm,
    showDialog,
    resolveDialog,
    resolveConfirm,
    cancelConfirm,
  }
})
