import { ref } from 'vue'
import { defineStore } from 'pinia'
import { buildQuery, fetchCategories, fetchEvents, fetchGroups, fetchLocations, fetchTemplates, fetchUsers, index } from '@/services/apiService'
import { ModelName } from '@/enums'
import { toast } from 'vue3-toastify'
import { captureException } from '@sentry/vue'

function failedToFetch(object: string, error: Error) {
  captureException(error)
  console.error(error)
  toast.error(`Failed to fetch ${object}: ${error}`)
}

/**
 * Include only more constant data that dont change so often. Items is better to fetch everytime as they can change very often
 *
 * Todo: implement expiration?
 */
export const useDataStore = defineStore('data', () => {
  const events = ref<App.Models.Event[]>([])
  const locations = ref<App.Models.Location[]>([])
  const sublocations = ref<App.Models.Sublocation[]>([])
  const users = ref<App.Models.User[]>([])
  const groups = ref([] as App.Models.Group[])
  const templates = ref([] as App.Models.Template[])
  const packages = ref([] as App.Models.Package[])
  const categories = ref([] as App.Models.Category[])
  const tags = ref([] as App.Models.Tag[])

  const pendingEventsPromise = ref<Promise<App.Models.Event[]> | null>(null)
  const pendingLocationsPromise = ref<Promise<App.Models.Location[]> | null>(null)
  const pendingSublocationsPromise = ref<Promise<App.Models.Sublocation[]> | null>(null)
  const pendingUsersPromise = ref<Promise<App.Models.User[]> | null>(null)
  const pendingGroupsPromise = ref<Promise<App.Models.Group[]> | null>(null)
  const pendingTemplatesPromise = ref<Promise<App.Models.Template[]> | null>(null)
  const pendingPackagesPromise = ref<Promise<App.Models.Package[]> | null>(null)
  const pendingCategoriesPromise = ref<Promise<App.Models.Category[]> | null>(null)
  const pendingTagsPromise = ref<Promise<App.Models.Tag[]> | null>(null)

  function getEvents(force: boolean = false): Promise<App.Models.Event[]> {
    if (pendingEventsPromise.value && !force) return pendingEventsPromise.value

    pendingEventsPromise.value = new Promise((resolve) => {
      if (!events.value.length || force) {
        fetchEvents(buildQuery({ end_utc: '>' + new Date().toISOString() }, [], [], ['id', 'name', 'external_id', 'start_utc', 'end_utc', 'location_id', 'description']))
          .then((response) => {
            events.value = response
            pendingEventsPromise.value = null
            resolve(response)
          })
          .catch((err) => toast.error('Failed to fetch events ' + err))
      } else {
        pendingEventsPromise.value = null
        resolve(events.value)
      }
    })

    return pendingEventsPromise.value
  }

  function getLocations(force: boolean = false): Promise<App.Models.Location[]> {
    if (pendingLocationsPromise.value && !force) return pendingLocationsPromise.value

    pendingLocationsPromise.value = new Promise((resolve) => {
      if (!locations.value.length || force) {
        fetchLocations()
          .then((response) => {
            locations.value = response
            pendingLocationsPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('locations', err))
      } else {
        pendingLocationsPromise.value = null
        resolve(locations.value)
      }
    })

    return pendingLocationsPromise.value
  }

  function getUsers(force: boolean = false): Promise<App.Models.User[]> {
    if (pendingUsersPromise.value && !force) return pendingUsersPromise.value

    pendingUsersPromise.value = new Promise((resolve) => {
      if (!users.value.length || force) {
        fetchUsers()
          .then((response) => {
            users.value = response
            pendingUsersPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('users', err))
      } else {
        pendingUsersPromise.value = null
        resolve(users.value)
      }
    })

    return pendingUsersPromise.value
  }

  function getGroups(force: boolean = false, query: string = 'include=cover'): Promise<App.Models.Group[]> {
    if (pendingGroupsPromise.value && !force) return pendingGroupsPromise.value

    pendingGroupsPromise.value = new Promise((resolve) => {
      if (!groups.value.length || force) {
        fetchGroups(query)
          .then((response) => {
            groups.value = response
            pendingGroupsPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('groups', err))
      } else {
        pendingGroupsPromise.value = null
        resolve(groups.value)
      }
    })

    return pendingGroupsPromise.value
  }

  function getTemplates(force: boolean = false, query: string = 'include=templateGroupDetails'): Promise<App.Models.Template[]> {
    if (pendingTemplatesPromise.value && !force) return pendingTemplatesPromise.value

    pendingTemplatesPromise.value = new Promise((resolve) => {
      if (!templates.value.length || force) {
        fetchTemplates(query)
          .then((response) => {
            templates.value = response
            pendingTemplatesPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('templates', err))
      } else {
        pendingTemplatesPromise.value = null
        resolve(templates.value)
      }
    })

    return pendingTemplatesPromise.value
  }

  function getPackages(force: boolean = false, query: string = 'include=groups,items,cover'): Promise<App.Models.Package[]> {
    if (pendingPackagesPromise.value && !force) return pendingPackagesPromise.value

    pendingPackagesPromise.value = new Promise((resolve) => {
      if (!packages.value.length || force) {
        index<App.Models.Package>(ModelName.Package, query)
          .then((response) => {
            packages.value = response
            pendingPackagesPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('packages', err))
      } else {
        pendingPackagesPromise.value = null
        resolve(packages.value)
      }
    })

    return pendingPackagesPromise.value
  }

  function getCategories(force: boolean = false): Promise<App.Models.Category[]> {
    if (pendingCategoriesPromise.value && !force) return pendingCategoriesPromise.value

    pendingCategoriesPromise.value = new Promise((resolve) => {
      if (!categories.value.length || force) {
        fetchCategories()
          .then((response) => {
            categories.value = response
            pendingCategoriesPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('categories', err))
      } else {
        pendingCategoriesPromise.value = null
        resolve(categories.value)
      }
    })

    return pendingCategoriesPromise.value
  }

  function getSublocations(force: boolean = false): Promise<App.Models.Sublocation[]> {
    if (pendingSublocationsPromise.value && !force) return pendingSublocationsPromise.value

    pendingSublocationsPromise.value = new Promise((resolve) => {
      if (!sublocations.value.length || force) {
        index<App.Models.Sublocation>(ModelName.Sublocation)
          .then((response) => {
            sublocations.value = response
            pendingSublocationsPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('sublocations', err))
      } else {
        pendingSublocationsPromise.value = null
        resolve(sublocations.value)
      }
    })

    return pendingSublocationsPromise.value
  }

  function getTags(force: boolean = false): Promise<App.Models.Tag[]> {
    if (pendingTagsPromise.value && !force) return pendingTagsPromise.value

    pendingTagsPromise.value = new Promise((resolve) => {
      if (!tags.value.length || force) {
        index<App.Models.Tag>(ModelName.Tag)
          .then((response) => {
            tags.value = response
            pendingTagsPromise.value = null
            resolve(response)
          })
          .catch((err) => failedToFetch('tags', err))
      } else {
        pendingTagsPromise.value = null
        resolve(tags.value)
      }
    })

    return pendingTagsPromise.value
  }

  return {
    events,
    locations,
    users,
    groups,
    templates,
    packages,
    categories,
    sublocations,
    tags,

    getEvents,
    getLocations,
    getUsers,
    getGroups,
    getTemplates,
    getPackages,
    getCategories,
    getSublocations,
    getTags,
  }
})
