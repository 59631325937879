<script setup lang="ts">
import DashboardFilter from '@/components/filters/DashboardFilter.vue'
import { transformFilterObjectToQueryReady } from '@/components/filters/helpers'
import Button from '@/components/forms/Button.vue'
import GridComponent from '@/components/GridComponent.vue'
import LoaderWrapper from '@/components/LoaderWrapper.vue'
import ReservationGridItem from '@/components/ReservationGridItem.vue'
import { buildQuery, fetchReservationsNonPaginated, saveEventApiLogin } from '@/services/apiService'
import { useDataStore } from '@/stores/data'
import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import { captureException } from '@sentry/vue'

const userStore = useUserStore()
const loading = ref(true)
const loadingReservations = ref(false)
const filters = ref<object>({})
const reservations = ref<App.Models.Reservation[]>([])
const dataStore = useDataStore()
const users = ref<App.Models.User[]>([])
const locations = ref<App.Models.Location[]>([])
dataStore.getUsers().then((response) => (users.value = response))
dataStore.getLocations().then((response) => (locations.value = response))

const route = useRoute()

// here we must check if there is code and
// /?code=1234&event_api_login=true
if (route.query && route.query.code && route.query.event_api_login) {
  const code = route.query.code
  const eventApiLogin = route.query.event_api_login === 'true'
  if (eventApiLogin) {
    saveEventApiLogin({ code: code })
      .then((response) => {
        if (response.success) {
          toast.success('Successfully save event API login')
        } else {
          toast.error(response.message)
          console.error(response.message)
        }
      })
      .catch((err) => {
        toast.error('Failed to save event API login')
        console.log(err)
        captureException(err)
      })
  }
}

function applyFilter(f) {
  filters.value = f
  const obj = { ...f }
  if (obj.status === 'all') {
    delete obj.status
  }

  const transformedQuery =
    f.external_event_id?.length > 0 || f.id?.length > 0
      ? transformFilterObjectToQueryReady(obj, ['external_event_id', 'id'])
      : transformFilterObjectToQueryReady(obj, ['user_id', 'location_id', 'status', 'date', 'external_event_id', 'id'])

  loadingReservations.value = true
  fetchReservationsNonPaginated(buildQuery(transformedQuery))
    .then((res) => {
      console.log(res)
      reservations.value = res
    })
    .then(() => (loadingReservations.value = false))
    .catch((err) => {
      toast.error('Failed to load reservations')
      loadingReservations.value = false
      captureException(err)
      console.error(err)
    })
}

onMounted(() => {
  document.title = 'Dashboard | Gear Rental | SlidesLive'
})
</script>

<template>
  <LoaderWrapper :loading="loading" :fullscreen="true">
    <div class="flex w-full px-2 py-5 space-x-8 min-h-empty md:px-5">
      <div class="w-full">
        <div class="flex flex-col justify-between w-full mb-6 xs:flex-row">
          <h1>Overview of reservations</h1>
        </div>
        <div class="w-full">
          <DashboardFilter @loaded="loading = false" @filter="applyFilter" />
          <LoaderWrapper :loading="loadingReservations">
            <GridComponent v-if="reservations && reservations.length > 0" :length="reservations.length" class="mb-8">
              <ReservationGridItem
                v-for="reservation in reservations.sort((a, b) => new Date(a.start_utc).getTime() - new Date(b.start_utc).getTime())"
                :key="reservation.id"
                :reservation="reservation"
                :users="users"
                :locations="locations"
              ></ReservationGridItem>
            </GridComponent>
            <div v-else-if="reservations && reservations.length !== undefined && !loadingReservations" class="text-white">
              <div v-if="filters.user_id?.length === 0 || filters.user_id?.indexOf(userStore.getUser().id) !== -1">
                <div class="mb-4">You have not created any reservation so far.</div>
                <div>
                  <router-link :to="{ name: 'CreateBooking' }">
                    <Button variant="primary">Create new reservation</Button>
                  </router-link>
                </div>
              </div>
              <div v-else>Selected user does not have any reservation in certain location.</div>
            </div>
          </LoaderWrapper>
        </div>
      </div>
    </div>
  </LoaderWrapper>
</template>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
