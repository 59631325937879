<template>
  <router-link v-if="!!to" :ref="root" :to="to" :disabled="disabled" tabindex="0" :class="computedClass" :title="title" @mousemove="mouseMove" v-tooltip="tooltip">
    <slot>Button</slot>
  </router-link>
  <a
    v-else-if="!!href"
    :ref="root"
    :href="href"
    :target="href.startsWith('http') ? '_blank' : ''"
    :disabled="disabled"
    tabindex="0"
    class="block"
    :class="computedClass"
    :title="title"
    @mousemove="mouseMove"
    @click.stop
    v-tooltip="tooltip"
  >
    <slot>Button</slot>
  </a>
  <button
    v-else
    :ref="root"
    :type="type"
    :disabled="disabled || loading"
    tabindex="0"
    class="relative"
    :class="computedClass"
    :title="title"
    @mousemove="mouseMove"
    v-tooltip="tooltip"
  >
    <slot>Button</slot>
    <Loader :show="loading" />
  </button>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue'
import Loader from '@/components/Loader.vue'

export default defineComponent({
  name: 'Button',
  components: { Loader },
  props: {
    type: {
      type: String as PropType<'button' | 'submit' | 'reset' | string>,
      default: 'button',
    },
    variant: {
      type: String as PropType<string>,
      default: 'primary',
    },
    to: {
      type: [Object, String] as PropType<object | string | null>,
      default: null,
    },
    href: {
      type: String as PropType<string | null>,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    shine: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    tooltip: {
      type: [String, Object] as PropType<string | object | null>,
      default: undefined,
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null)

    const computedClass = computed(() => [
      props.small ? `${props.variant}-sm` : props.variant,
      props.shine ? 'shiny-button' : '',
      props.selected ? `${props.variant}-selected` : '',
      props.loading ? 'cursor-progress' : '',
      props.disabled ? 'cursor-not-allowed opacity-50' : '',
      'focus-visible:ring-2 focus-visible:ring-zinc-300',
    ])

    function mouseMove(e: MouseEvent) {
      const el = root.value
      if (!el || !props.shine) return

      const { x, y } = el.getBoundingClientRect()
      const target = e.target as HTMLElement
      target.style.setProperty('--x', `${e.clientX - x}`)
      target.style.setProperty('--y', `${e.clientY - y}`)
    }

    return {
      root,
      computedClass,
      mouseMove,
    }
  },
})
</script>

<style scoped>
.primary.shiny-button {
  @apply hover:bg-primary hover:text-dark;
}

.primary,
.secondary,
.info,
.danger,
.warning,
.primary-md,
.secondary-md,
.info-md,
.danger-md,
.warning-md,
.danger-sm,
.primary-sm,
.secondary-sm,
.info-sm,
.warning-sm,
.primary-xs,
.secondary-xs,
.info-xs,
.danger-xs,
.warning-xs,
.primary-xxs,
.secondary-xxs,
.info-xxs,
.danger-xxs,
.warning-xxs {
  @apply whitespace-nowrap inline-flex items-center justify-center bg-origin-border select-none transition-all;
  @apply outline-none;
}

.primary,
.secondary,
.info,
.danger,
.warning {
  @apply h-11 xl:px-12 px-8 py-2 border-0 rounded-md font-bold;
}

.primary-md,
.secondary-md,
.info-md,
.danger-md,
.warning-md {
  @apply h-9 xl:px-8 px-4 py-2 border-0 rounded-md text-sm font-bold;
}

.danger-sm,
.primary-sm,
.secondary-sm,
.info-sm,
.warning-sm {
  @apply h-7 text-sm xl:px-8 px-6 py-2 border-0 rounded-md  font-bold;
}

.primary-xs,
.secondary-xs,
.info-xs,
.danger-xs,
.warning-xs {
  @apply h-6
  text-[0.8rem] xl:px-4 px-3 py-2 border-0 rounded-md font-bold;
}
.primary-xxs,
.secondary-xxs,
.info-xxs,
.danger-xxs,
.warning-xxs {
  @apply h-5
  text-[0.6rem] xl:px-3 px-2 py-1.5 border-0 rounded-md font-bold;
}

.primary,
.primary-md,
.primary-sm,
.primary-xs,
.primary-xxs,
.warning-xxs {
  @apply text-dark;
  @apply bg-primary/80 shadow-md hover:bg-primary/90 hover:text-black;
  &:not(:disabled) {
    @apply active:outline-4 active:outline-primary;
  }
}

.secondary,
.secondary-md,
.secondary-sm,
.secondary-xs,
.secondary-xxs,
.warning-xxs {
  @apply bg-secondary/20 text-white shadow-md border border-secondary hover:bg-secondary hover:border-opacity-0;
  &:not(:disabled) {
    @apply active:outline-4 active:outline-secondary;
  }
}

.info,
.info-md,
.info-sm,
.info-xs,
.info-xxs {
  @apply bg-zinc-300/10 text-white shadow-md border border-zinc-300 hover:bg-zinc-300/100 hover:border-opacity-0 hover:text-dark;
  &:not(:disabled) {
    @apply active:outline-4 active:outline-zinc-300;
  }
}

.warning,
.warning-md,
.warning-sm,
.warning-xs,
.warning-xxs {
  @apply bg-warning/10 text-white shadow-md border border-warning hover:bg-warning hover:border-opacity-0;
  &:not(:disabled) {
    @apply active:outline-4 active:outline-warning;
  }
}

.danger,
.danger-md,
.danger-sm,
.danger-xs,
.danger-xxs {
  @apply bg-error/10 text-white shadow-md border border-error hover:bg-error hover:border-opacity-0;
  &:not(:disabled) {
    @apply active:outline-4 active:outline-error;
  }
}

.secondary:disabled,
.secondary-md:disabled,
.secondary-sm:disabled,
.secondary-xs:disabled,
.secondary-xxs:disabled,
.warning-xxs:disabled {
  @apply bg-secondary/40 cursor-not-allowed shadow-none hover:border-secondary border-2;
}
.primary:disabled,
.primary-md:disabled,
.primary-sm:disabled,
.primary-xs:disabled,
.primary-xxs:disabled,
.warning-xxs:disabled {
  @apply shadow-none bg-primary/40 cursor-not-allowed text-white/40;
}

.shiny-button {
  /* add this property to our button, */
  /* so we can position our shiny gradient *relative* to the button itself */
  position: relative;
  /* then, make sure our shiny effect */
  /* doesn't "overflow" outside of our button */
  transform-style: preserve-3d;
  overflow: hidden;
}

.shiny-button:hover::after {
  opacity: 0.3;
}

.shiny-button:active::after {
  opacity: 0;
}

.shiny-button::after {
  /* all pseudo-elements need "content" to work. We'll make it empty here */
  content: '';
  opacity: 0;
  padding: 0;
  top: calc(var(--y, 0) * 1px - 100px);
  left: calc(var(--x, 0) * 1px - 100px);
  position: absolute;
  width: 600px;
  height: 600px;
  /* make sure the gradient isn't too bright */
  /* add a circular gradient that fades out on the edges */
  background: radial-gradient(#ffffff, #c8f60700 60%);
  transition: opacity 0.6s;
  transform: translateZ(-1px);
}
</style>
