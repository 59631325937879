/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ReservationStatus {
  Active = 'active',
  Returned = 'returned',
}

export enum ItemStatus {
  InStock = 'ready',
  PickedUp = 'booked',
  LateReturn = 'late_return',
  Lost = 'lost',
  Missing = 'missing',
  OutOfOrder = 'out_of_order',
  Dead = 'dead',
}

export enum NotAvailableForBookingStatus {
  Lost = 'lost',
  Missing = 'missing',
  OutOfOrder = 'out_of_order',
  Dead = 'dead',
}

export enum NotAvailableForBookingPreviousStatus {
  OutOfOrder = 'out_of_order',
  Dead = 'dead',
}

export enum SettingsKeys {
  DefaultLocation = 'default_location',
  ScannerInputType = 'scanner_input_type', // QR / ean8 / ean13
  PerPage = 'per_page',
  ScannerInput = 'scanner_input', // Scanner + input, input or scanner only
}

export enum ScannerInput {
  ScannerAndInput = 'scanner_and_input',
  ScannerOnly = 'scanner_only',
  InputOnly = 'input_only',
}

export enum ModelName {
  Category = 'categories',
  Group = 'groups',
  Location = 'locations',
  Sublocation = 'sublocations',
  Event = 'events',
  Gearcheck = 'gearchecks',
  GearcheckItem = 'gearcheck-items',
  ItemHistory = 'item-histories',
  ItemLogEntry = 'item-log-entries',
  Log = 'logs',
  MailLog = 'mail-logs',
  Reservation = 'reservations',
  ReservedGroupDetail = 'reserved-group-details',
  User = 'users',
  Template = 'templates',
  Package = 'packages',
  Tag = 'tags',
  Item = 'items',
  Media = 'media',
}

export enum ItemStatusLabel {
  InStock = 'IN STOCK',
  PickedUp = 'PICKED UP',
  LateReturn = 'LATE RETURN',
  Lost = 'LOST',
  Missing = 'MISSING',
  OutOfOrder = 'OUT OF ORDER',
  Dead = 'DEAD',
}

export enum ItemStatusColor {
  InStock = '#4ade80',
  PickedUp = '#fb923c',
  LateReturn = '#fb923c',
  Lost = '#f87171',
  Missing = '#fb923c',
  OutOfOrder = '#fb923c',
  Dead = '#f87171',
}

export enum ItemLabel {
  TO_FIX = 'TO_FIX',
  TO_TEST = 'TO_TEST',
}
