<script setup lang="ts">
import router from '@/router'
import { nextTick, onMounted, ref, watch } from 'vue'
import { cleanFilter, decodeFilter } from './helpers'
import { useRoute } from 'vue-router'
import Button from '../forms/Button.vue'
import Checkbox from '../forms/Checkbox.vue'

const props = withDefaults(
  defineProps<{
    filterName: string
    classList?: string
    filters?: object
    autoApply?: boolean
    autoApplyOnInit?: boolean
    saveFilterButton?: boolean
    enableSaving?: boolean
    resetButton?: boolean
  }>(),
  {
    classList: 'p-4 mt-6 rounded-md bg-elevation1',
    filters: {},
    autoApply: false,
    autoApplyOnInit: true,
    saveFilterButton: true,
    enableSaving: true,
    resetButton: true,
  },
)

const emit = defineEmits(['apply', 'reset', 'set'])

const saveFilter = ref(true)
const appliedFilters = ref({})
const routeName = ref<string | null>(null)

const route = useRoute()

const initted = ref(false)
onMounted(() => {
  if (!initted.value) {
    initted.value = true
    if (props.autoApplyOnInit) {
      emit('apply')
    }
  }
})

watch(saveFilter, function (newVal) {
  window.localStorage.setItem(`${props.filterName}_save`, newVal.toString())
})

watch(
  () => props.filters,
  function (newVal) {
    appliedFilters.value = newVal
    if (props.autoApply) {
      apply()
      router.push({ path: route.fullPath, query: cleanFilter({ ...appliedFilters.value }) }) // route change will emit filter event
    }
    if (props.enableSaving) {
      if (newVal?.code) {
        delete newVal.code
      }
      if (newVal?.event_api_login) {
        delete newVal.event_api_login
      }
      window.localStorage.setItem(props.filterName, JSON.stringify(newVal))
    }
  },
  { deep: true },
)

watch(route, function (newVal) {
  if (newVal.name === routeName.value) {
    const decoded = decodeFilter(newVal.query)
    for (const key of Object.keys(decoded)) {
      if (appliedFilters.value[key] !== decoded[key]) {
        getFromQuery(decoded)
        break
      }
    }
  }
})
nextTick(() => {
  routeName.value = route.name
})
const decoded = decodeFilter(route.query)
saveFilter.value = window.localStorage.getItem(`${props.filterName}_save`) == 'false' ? false : true
const previousFilter = window.localStorage.getItem(props.filterName)
if (Object.keys(decoded).length > 0 && !decoded.code) {
  decoded.date = computeDateRangeFromType(decoded.rangetype ?? 'custom') ?? decoded.date
  emit('set', decoded)
  console.log('set', decoded)
} else if (saveFilter.value && previousFilter && props.enableSaving) {
  const parsed = JSON.parse(previousFilter)
  parsed.date = computeDateRangeFromType(parsed.rangetype ?? 'custom') ?? parsed.date
  emit('set', parsed)
}

function apply() {
  /*if (props.autoApply) {
    emit('apply')
  }*/
  emit('apply')
  router.push({ path: route.fullPath, query: cleanFilter({ ...props.filters }) }) // route change will emit filter event
  if (!props.autoApply) {
    console.log('apply', props.filters, cleanFilter({ ...props.filters }))
    //this.$router.push({ query: Object.assign({}, this.$route.query, cleanFilter({...props.filters}) )});
  }
}
function reset() {
  console.log('reset')
  emit('reset')
  if (!props.autoApply) {
    emit('apply')
    router.push({ path: route.fullPath, query: {} })
  }
}
function getFromQuery(decoded) {
  console.log('getFromQuery', decoded)
  emit('set', decoded)
  if (!props.autoApply) {
    emit('apply')
  }
}
function computeDateRangeFromType(type) {
  const returnArray = []
  if (type === 'custom') {
    return null
  }
  if (type === 'plus_60') {
    const startData = new Date()
    startData.setDate(startData.getDate() - 7)

    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 60)

    returnArray.push(startData.toISOString().split('T')[0])
    returnArray.push(endDate.toISOString().split('T')[0])
  }
  if (type === 'plus_180') {
    const startData = new Date()
    startData.setDate(startData.getDate() - 7)

    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 180)

    returnArray.push(startData.toISOString().split('T')[0])
    returnArray.push(endDate.toISOString().split('T')[0])
  }
  if (type === 'up_to_now') {
    const startData = new Date()
    startData.setDate(startData.getDate() - 9999)

    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 1)

    returnArray.push(startData.toISOString().split('T')[0])
    returnArray.push(endDate.toISOString().split('T')[0])
  }
  console.log('computeDateRangeFromType', type, returnArray)
  return returnArray
}
</script>

<template>
  <div :class="classList">
    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6 lg:grid-cols-9 xl:grid-cols-12">
      <slot></slot>

      <div v-if="resetButton || autoApply !== true" class="flex flex-col xss:items-center xss:justify-between xss:flex-row sm:col-span-6 lg:col-span-9 xl:col-span-12">
        <div>
          <Checkbox v-if="saveFilterButton && enableSaving" v-model="saveFilter" formname="saveFilter" label="Save filter" />
        </div>
        <div class="flex flex-col-reverse gap-2 xss:flex-row xss:items-center xss:justify-end" :class="{ 'mt-4 xss:mt-0': saveFilterButton && enableSaving }">
          <Button v-if="resetButton" class="" variant="secondary-md" @click="reset">Reset</Button>
          <Button v-if="autoApply !== true" variant="primary-md" @click="apply">Apply</Button>
        </div>
      </div>
    </div>
  </div>
</template>
