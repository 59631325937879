<script setup lang="ts">
import { useRoute } from 'vue-router'
import BaseLayout from '@/layouts/BaseLayout.vue'
import { computed, onMounted, ref, watchEffect } from 'vue'
import AuthLayout from './layouts/AuthLayout.vue'
import { useDialogStore } from './stores/dialog'
import Modal from './components/Modal.vue'
import Button from './components/forms/Button.vue'

const route = useRoute()

// Decide layout based on route name, path, or meta field
const layoutReady = ref(false)

const dialogStore = useDialogStore()
// Decide layout based on route meta and set layoutReady
const isBaseLayout = computed(() => route.meta.layout === 'base')

// Wait until the initial route has been confirmed
onMounted(() => {
  layoutReady.value = true
})

// Watch for route changes and update the layout readiness
watchEffect(() => {
  layoutReady.value = false // Hide until layout check completes
  layoutReady.value = true // Show after layout is confirmed
})

const informativeModal = computed(() => dialogStore.dialogs.length > 0)
const informativeModalTitle = computed(() => dialogStore.dialogs[0]?.title ?? '')

const confirmModal = computed(() => dialogStore.confirmDialogs.length > 0)
const confirmModalTitle = computed(() => dialogStore.confirmDialogs[0]?.title ?? '')
</script>

<template>
  <div class="min-h-empty p-0 m-0 text-white transition-all duration-700 ease-in-out bg-page">
    <div v-if="layoutReady && route.meta.layout">
      <div v-if="!isBaseLayout">
        <!-- Auth layout -->
        <AuthLayout></AuthLayout>
      </div>
      <div v-else>
        <!-- Default layout -->
        <BaseLayout></BaseLayout>
      </div>

      <!-- informative modal -->
      <Modal v-model="informativeModal" :title="informativeModalTitle" @close="dialogStore.resolveDialog">
        <div class="mt-2 text-zinc-200" v-html="dialogStore.dialogs[0]?.description"></div>
        <div class="mt-2 text-sm text-zinc-400" v-html="dialogStore.dialogs[0]?.additionalText"></div>
        <div class="flex justify-end gap-4 my-4">
          <Button @click="dialogStore.resolveDialog" variant="primary-sm">OK</Button>
        </div>
      </Modal>

      <!-- confirm modal -->
      <Modal
        v-model="confirmModal"
        :title="confirmModalTitle"
        @close="
          () => {
            if (dialogStore.confirmDialogs[0]?.preventCloseOnOutsideClick !== true) dialogStore.cancelConfirm(false)
          }
        "
      >
        <div class="mt-2 text-zinc-200" v-html="dialogStore.confirmDialogs[0]?.description"></div>
        <div class="mt-2 text-sm text-zinc-400" v-html="dialogStore.confirmDialogs[0]?.additionalText"></div>
        <div class="flex justify-end gap-4 my-4" v-if="dialogStore.confirmDialogs[0]?.additionalButtons">
          <Button
            v-for="(button, index) in dialogStore.confirmDialogs[0]?.additionalButtons"
            :key="index"
            @click="
              () => {
                dialogStore.resolveConfirm()
                button.onClick()
              }
            "
            :variant="button.variant"
            >{{ button.text }}</Button
          >
        </div>
        <div class="flex justify-end gap-4 my-4">
          <Button @click="dialogStore.cancelConfirm(true)" :variant="dialogStore.confirmDialogs[0]?.type === 'danger' ? 'secondary-sm' : 'warning-sm'">{{
            dialogStore.confirmDialogs[0]?.buttonCancelText ?? 'Cancel'
          }}</Button>
          <Button @click="dialogStore.resolveConfirm" :variant="dialogStore.confirmDialogs[0]?.type === 'danger' ? 'danger-sm' : 'primary-sm'" autofocus>{{
            dialogStore.confirmDialogs[0]?.buttonConfirmText ?? 'Yes'
          }}</Button>
        </div>
      </Modal>
    </div>
    <div v-else class="fixed top-0 bottom-0 left-0 right-0" style="background: rgba(0, 0, 0, 0.45); z-index: 1000">
      <div class="flex items-center justify-center w-screen h-screen">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
  </div>
</template>
