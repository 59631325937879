<template>
  <div
    class="relative p-0 border focus-within:z-10 border-opacity-20 group"
    :class="[
      invalid || invalidDate ? 'bg-error/20 border-error outline outline-error' : 'bg-inputs border-zinc-100',
      disabled ? 'bg-gray-700/5 opacity-50' : '',
      readonly ? 'cursor-not-allowed bg-gray-400/5' : '',
      innerType === 'hidden' ? 'hidden' : '',
      !buttonName ? 'rounded-md' : 'rounded-l-md rounded-r-[10.5px]',
      innerType === 'text' || innerType === 'number' || innerType === 'password' ? 'inputwrapper' : '',
    ]"
  >
    <label
      :for="formname"
      class="text-xs font-semibold flex justify-between absolute -top-[10px] left-[7px] rounded-sm px-1 z-10"
      :class="[disabled ? '!text-gray-400' : '', invalid || invalidDate ? 'text-red-200 invalid' : 'text-zinc-300']"
    >
      <span>
        {{ label }} {{ invalid }}
        <span v-if="required && !disabled" class="text-error">*</span>
        <template v-if="invalidDate">&nbsp;[This time does not exist!]</template>
      </span>
      <div v-if="badge" class="bg-secondary/75 text-white rounded-full text-xs font-semibold h-4 ml-2 px-1.5 whitespace-nowrap">
        {{ badge }}
      </div>
      <div v-if="optionalBadge" class="bg-info/75 text-dark rounded-full text-xs font-semibold h-4 ml-2 px-1.5 whitespace-nowrap">Optional</div>
      <div v-if="readonly" class="bg-info/75 text-dark rounded-full text-xs font-semibold h-4 ml-2 px-1.5 whitespace-nowrap">Read Only</div>

      <svg
        v-if="tooltip"
        v-tooltip="{
          content: tooltip,
          html: true,
          placement: 'top-end',
          skidding: 16,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="flex-shrink-0 w-5 h-5 ml-1 -mr-2 rounded-full text-zinc-400 cursor-help"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </label>
    <div class="relative flex">
      <div v-if="['date', 'datetime', 'datetime-local'].includes(innerType)" class="flex flex-wrap gap-1 mt-[6px] ml-[5px] pb-[6px]">
        <input type="hidden" :name="formname" :value="date.innerFormatted" />

        <input
          v-if="!disabled"
          v-model="date.innerFormatted"
          :disabled="disabled"
          :type="innerType === 'date' ? 'date' : 'datetime-local'"
          class="w-[28px] bg-zinc-700 text-zinc-200 py-0 px-[4px] rounded-full border-zinc-500 text-sm"
          name="datetime-local"
          tabindex="-1"
          @input="sendInput"
        />

        <div class="whitespace-nowrap">
          <select
            v-model="date.day"
            :disabled="disabled"
            class="h-7 min-w-[60px] rounded-l-full text-sm pl-4 !pr-0 py-0 bg-zinc-700 border-zinc-500 text-zinc-100 focus:border-info focus:ring-info focus:ring-2"
            @change="sendInput"
          >
            <option :value="null" disabled>Day</option>
            <option v-for="day in 31" :value="day" :key="'day-' + day">{{ day }}</option>
          </select>
          <select
            v-model="date.month"
            :disabled="disabled"
            class="h-7 min-w-[60px] rounded-none -ml-[1px] text-sm pl-4 !pr-2 py-0 bg-zinc-700 border-zinc-500 text-zinc-100 focus:border-info focus:ring-info focus:ring-2"
            @change="sendInput"
          >
            <option :value="null" disabled>Mon.</option>
            <option v-for="(month, index) in dateOptions.months" :value="month.value" :key="'month-' + index">
              {{ month.label }}
            </option>
          </select>
          <select
            v-model="date.year"
            :disabled="disabled"
            class="h-7 min-w-[80px] rounded-r-full -ml-[1px] text-sm pl-4 !pr-2 py-0 bg-zinc-700 border-zinc-500 text-zinc-100 focus:border-info focus:ring-info focus:ring-2"
            @change="sendInput"
          >
            <option :value="null" disabled>Year</option>
            <option v-for="(year, index) in dateOptions.years" :value="year" :key="'year-' + index">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="whitespace-nowrap">
          <select
            v-if="innerType === 'datetime' || innerType === 'datetime-local'"
            v-model="date.hour"
            :disabled="disabled"
            class="h-7 min-w-[60px] rounded-l-full text-sm pl-4 !pr-2 py-0 bg-zinc-700 border-zinc-500 text-zinc-100 focus:border-info focus:ring-info focus:ring-2"
            @change="sendInput"
          >
            <option :value="null" disabled>Hr</option>
            <option v-for="hour in dateOptions.hours" :value="hour" :key="'hour-' + hour">
              {{ hour }}
            </option>
          </select>
          <select
            v-if="innerType === 'datetime' || innerType === 'datetime-local'"
            v-model="date.minute"
            :disabled="disabled"
            class="h-7 min-w-[60px] rounded-r-full -ml-[1px] text-sm pl-4 !pr-2 py-0 bg-zinc-700 border-zinc-500 text-zinc-100 focus:border-info focus:ring-info focus:ring-2"
            @change="sendInput"
          >
            <option :value="null" disabled>Min</option>
            <option v-for="minute in dateOptions.minutes" :value="minute" :key="'minute-' + minute">
              {{ minute }}
            </option>
          </select>
        </div>
      </div>
      <input
        v-else-if="innerType === 'number'"
        :id="formname"
        class=""
        :placeholder="placeholder"
        :value="formattedValue"
        type="text"
        :name="formname"
        :readonly="readonly"
        :disabled="disabled"
        tabindex="0"
        :min="min"
        :max="max"
        :step="step"
        :minlength="minlength"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        @input="sendInput"
        @blur="onBlur"
        @focus="onFocus"
        v-tooltip="readonly ? 'Field is read-only' : null"
        :class="{
          'cursor-not-allowed': disabled,
          '': small === false,
          'pt-2 pb-1': larger,
          'cursor-help': readonly,
        }"
      />
      <input
        v-else
        :id="formname"
        class=""
        :placeholder="placeholder"
        :value="modelValue"
        :type="innerType"
        :name="formname"
        :readonly="readonly"
        :disabled="disabled"
        tabindex="0"
        :min="min"
        :max="max"
        :step="step"
        :minlength="minlength"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :list="type === 'color' ? 'presetColors' : null"
        @input="sendInput"
        @blur="onBlur"
        @focus="onFocus"
        @keyup.enter="
          () => {
            emit('enter')
            if (callActionOnEnter) {
              emit('action')
            }
          }
        "
        v-tooltip="readonly ? 'Field is read-only' : null"
        :class="{
          'cursor-not-allowed': disabled,
          '': small === false,
          'pt-2 pb-1': larger,
          'cursor-help': readonly,
        }"
      />
      <ArrowTopRightOnSquareIcon
        v-tooltip="'Open link'"
        class="absolute w-5 right-2 cursor-pointer text-zinc-500 hover:opacity-80 transition-opacity mt-[11px]"
        v-if="innerType === 'url' && modelValue?.length > 0"
        @click="openLink"
      />
      <EyeIcon
        v-tooltip="'Show password'"
        class="absolute w-5 right-2 cursor-pointer text-zinc-500 hover:opacity-80 transition-opacity mt-[11px]"
        v-if="innerType === 'password' && type === 'password'"
        @click="revealPassword"
      />
      <EyeSlashIcon
        v-tooltip="'Hide password'"
        class="absolute w-5 right-2 cursor-pointer text-zinc-500 hover:opacity-80 transition-opacity mt-[11px]"
        v-if="innerType === 'text' && type === 'password'"
        @click="hidePassword"
      />
      <TrashIcon
        v-if="type !== 'password' && trashButton"
        v-tooltip="'Delete'"
        class="absolute w-5 right-2 cursor-pointer text-zinc-500 hover:opacity-80 transition-opacity mt-[11px]"
        @click="emit('trash', { label, value: modelValue })"
      />
      <template v-if="buttonName">
        <div class="mt-[8px] mr-[9px]">
          <Button @click="emit('action')" variant="info-xs">
            {{ buttonName }}
          </Button>
        </div>
      </template>
      <datalist id="presetColors">
        <option>#000000</option>
        <option>#07ad33</option>
        <option>#8ba641</option>
        <option>#094ebd</option>
        <option>#1809bd</option>
        <option>#7700e6</option>
        <option>#de07ed</option>
        <option>#de0962</option>
        <option>#f2071f</option>
        <option>#f28807</option>
        <option>#54524e</option>
        <option>#1bcce3</option>
      </datalist>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, defineProps, defineEmits, nextTick } from 'vue'
import Button from './Button.vue'
import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/vue/24/solid'
import dayjs from 'dayjs'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

const props = withDefaults(
  defineProps<{
    modelValue: any
    formname?: string
    label?: string
    invalid?: string
    type?: string
    small?: boolean
    larger?: boolean
    buttonName?: string
    min?: number
    max?: number
    step?: number
    minlength?: number
    maxlength?: number
    placeholder?: string
    required?: boolean
    readonly?: boolean
    disabled?: boolean
    nolabel?: boolean
    autocomplete?: string | null
    badge?: string | null
    tooltip?: string | null
    optionalBadge?: boolean
    futureYears?: boolean
    trashButton?: boolean
    callActionOnEnter?: boolean
  }>(),
  {
    callActionOnEnter: false,
  },
)

const emit = defineEmits(['update:modelValue', 'buffered', 'validate', 'action', 'trash', 'blur', 'enter'])

// Reactive data
const formattedValue = ref<string | null>(null)
const innerType = ref<string>(props.type || 'text')
const date = ref({
  day: null as number | null,
  month: null as number | null,
  year: null as number | null,
  hour: null as number | null,
  minute: null as number | null,
  innerFormatted: null as string | null,
})
const dateOptions = ref({
  days: [] as number[],
  months: [] as { label: string; value: number }[],
  years: [] as number[],
  hours: [] as number[],
  minutes: [] as number[],
})
const invalidDate = ref(false)
let buffer: number | null = null

// Watchers
watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      if (props.modelValue !== null && (innerType.value === 'date' || innerType.value === 'datetime' || innerType.value === 'datetime-local')) {
        const innerDateObj = new Date(props.modelValue)
        if (props.futureYears && innerDateObj.getFullYear() < 2020) {
          return
        }
        date.value = {
          day: innerDateObj.getDate(),
          month: innerDateObj.getMonth() + 1,
          year: innerDateObj.getFullYear(),
          hour: innerDateObj.getHours(),
          minute: Math.ceil(innerDateObj.getMinutes() / 5) * 5,
          innerFormatted: date.value.innerFormatted,
        }
      } else {
        date.value = {
          day: null,
          month: null,
          year: null,
          hour: null,
          minute: null,
          innerFormatted: null,
        }
      }
      if (props.type === 'number') {
        formattedValue.value = formatNumberWithSpaces(props.modelValue)
      }
    })
  },
  { immediate: true, flush: 'post' },
)

// Mounted hook
onMounted(() => {
  if (['date', 'datetime', 'datetime-local'].includes(innerType.value)) {
    if (!props.futureYears) {
      for (let year = new Date().getFullYear() + 4; year > 1990; year--) {
        dateOptions.value.years.push(year)
      }
    } else {
      for (let year = new Date().getFullYear(); year < new Date().getFullYear() + 50; year++) {
        dateOptions.value.years.push(year)
      }
    }

    dateOptions.value.months = Array.from({ length: 12 }, (v, k) => ({
      label: String(k + 1),
      value: k + 1,
    }))

    dateOptions.value.hours = Array.from({ length: 24 }, (v, k) => k)
    dateOptions.value.minutes = Array.from({ length: 12 }, (v, k) => k * 5)

    if (props.modelValue !== null) {
      const innerDateObj = new Date(props.modelValue)
      if (innerDateObj.getFullYear() > 1970) {
        date.value.day = innerDateObj.getDate()
        date.value.month = innerDateObj.getMonth() + 1
        date.value.year = innerDateObj.getFullYear()
        date.value.hour = innerDateObj.getHours()
        date.value.minute = Math.ceil(innerDateObj.getMinutes() / 5) * 5
      }
    }
  }
})

// Methods
function formatNumberWithSpaces(value: any): string {
  console.log(value, innerType.value)
  if (!value) return value
  const cleanedValue = (value + '').replace(/[^\d.]/g, '')
  return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

function checkIfDateExists(year: number, month: number, day: number, hours: number, minute: number): boolean {
  month = month - 1
  const dateObj = new Date(year, month, day, hours, minute)
  return dateObj.getFullYear() === year && dateObj.getMonth() === month && dateObj.getDate() === day && dateObj.getHours() === hours && dateObj.getMinutes() === minute
}

function hidePassword() {
  innerType.value = 'password'
}

function revealPassword() {
  innerType.value = 'text'
}

function openLink() {
  window.open(props.modelValue, '_blank')
}

function sendInput(e: Event) {
  if (props.type === 'number') {
    const target = e.target as HTMLInputElement
    const cleanedValue = (target.value + '').replace(/[^\d.]/g, '')
    let parsedValue = parseFloat(cleanedValue)
    if (isNaN(parsedValue)) {
      parsedValue = null
    }
    emit('update:modelValue', parsedValue)
    formattedValue.value = formatNumberWithSpaces(parsedValue)
  } else if (innerType.value === 'date') {
    if (date.value.month && date.value.day && date.value.year) {
      date.value.innerFormatted = date.value.year + '-' + String(date.value.month).padStart(2, '0') + '-' + String(date.value.day).padStart(2, '0')
      emit('update:modelValue', new Date(date.value.innerFormatted).toISOString())
    }
  } else if (innerType.value === 'datetime' || innerType.value === 'datetime-local') {
    const target = e.target as HTMLInputElement
    if (e.type === 'input' && target.value.length === 16) {
      date.value.year = parseInt(target.value.slice(0, 4))
      date.value.month = parseInt(target.value.slice(5, 7))
      date.value.day = parseInt(target.value.slice(8, 10))
      date.value.hour = parseInt(target.value.slice(11, 13))
      date.value.minute = Math.floor(parseInt(target.value.slice(14, 16)) / 5) * 5
    }
    if (date.value.month !== null && date.value.day !== null && date.value.year !== null && date.value.hour !== null && date.value.minute !== null) {
      date.value.innerFormatted =
        date.value.year +
        '-' +
        String(date.value.month).padStart(2, '0') +
        '-' +
        String(date.value.day).padStart(2, '0') +
        'T' +
        String(date.value.hour ?? 0).padStart(2, '0') +
        ':' +
        String(date.value.minute ?? 0).padStart(2, '0')

      const dateString =
        date.value.year +
        '-' +
        String(date.value.month).padStart(2, '0') +
        '-' +
        String(date.value.day).padStart(2, '0') +
        'T' +
        String(date.value.hour ?? 0).padStart(2, '0') +
        ':' +
        String(date.value.minute ?? 0).padStart(2, '0') +
        ':00'

      const result = dayjs.tz(dateString, 'Europe/Prague').second(0).millisecond(0).utc().format()
      emit('update:modelValue', new Date(result).toISOString())
    }
  } else {
    const target = e.target as HTMLInputElement
    emit('update:modelValue', target.value)
  }
  clearTimeout(buffer as number)
  buffer = setTimeout(() => {
    const target = e.target as HTMLInputElement
    emit('buffered', target.value, e)
  }, 270)
}

function onBlur() {
  if (props.modelValue) {
    emit('validate')
  }
  emit('blur')
}

function onFocus() {
  if (props.modelValue) {
    emit('validate')
  }
}
</script>

<style scoped>
label {
  @apply bg-input-label-transparent;
}
label.invalid {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2e34+0,2e2e34+50,3d3d42+51,3d3d42+100 */
  background: linear-gradient(to bottom, #2e2e34 0%, #2e2e34 56%, #513531 57%, #513531 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
input[type='text'],
input[type='url'],
input[type='number'],
input[type='password'],
input[type='email'] {
  @apply rounded-[5px] block w-full border-0 pt-[10px] py-2 text-white placeholder:italic placeholder-zinc-300/40 bg-transparent;
  @apply focus:ring-2 focus:ring-zinc-300;
}
input[type='date'],
input[type='datetime-local'] {
  @apply bg-transparent;
}
.inputwrapper {
  @apply h-11;
}
input[type='color'] {
  @apply w-full h-[42px];
}
</style>
