import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from './AuthGuard'

const errorHandler = (err: Error) => {
  // If the chunk fails to load, do a page reload
  console.error(err)
  //window.location.reload()
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      // Scroll to top only when route name changes
      return { top: 0 }
    }
    // Return savedPosition or no scroll if needed
    return savedPosition || {}
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('../views/User/Login.vue').catch(errorHandler),
      meta: { authRequired: false, adminOnly: false, layout: 'auth' },
    },
    {
      name: 'Logout',
      path: '/logout',
      component: () => import('../views/User/Logout.vue').catch(errorHandler),
      meta: { authRequired: false, adminOnly: false, layout: 'auth' },
    },
    {
      name: 'CreateBooking',
      path: '/create',
      component: () => import('../views/Reservation/CreateReservation.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Pickup',
      path: '/pickup/:reservationId?',
      component: () => import('../views/Pickup.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Return',
      path: '/return/:reservationId?',
      component: () => import('../views/Return.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Gearlist',
      path: '/gear-list',
      component: () => import('../views/Gearlist.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Settings',
      path: '/settings',
      component: () => import('../views/SettingsView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'DataManage',
      path: '/data',
      component: () => import('../views/DataManage/DataIndex.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewTimelineGroup',
      path: '/view/timeline/:groupId',
      component: () => import('../views/TimelineGroupView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewTimeline',
      path: '/view/timeline',
      component: () => import('../views/TimelineView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ViewReleasedItems',
      path: '/view/released-items',
      component: () => import('../views/ReleasedItemsView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Reservations',
      path: '/bookings',
      component: () => import('../views/DataManage/Reservation/ReservationList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ReservationDetail',
      path: '/bookings/:id',
      component: () => import('../views/DataManage/ReservationDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Users',
      path: '/users',
      component: () => import('../views/DataManage/User/UserList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'UserDetail',
      path: '/users/:id',
      component: () => import('../views/DataManage/User/UserDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Categories',
      path: '/categories',
      component: () => import('../views/DataManage/Category/CategoryList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'CategoryCreate',
      path: '/categories/add',
      component: () => import('../views/DataManage/Category/CategoryAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'CategoryDetail',
      path: '/categories/:id',
      component: () => import('../views/DataManage/Category/CategoryDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Groups',
      path: '/groups',
      component: () => import('../views/DataManage/Group/GroupsList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'GroupCreate',
      path: '/groups/add',
      component: () => import('../views/DataManage/Group/GroudAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'GroupDetail',
      path: '/groups/:id',
      component: () => import('../views/DataManage/Group/GroupDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Tags',
      path: '/tags',
      component: () => import('../views/DataManage/Tag/TagList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'TagCreate',
      path: '/tags/add',
      component: () => import('../views/DataManage/Tag/TagAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'TagDetail',
      path: '/tags/:id',
      component: () => import('../views/DataManage/Tag/TagDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'Tagging',
      path: '/tagging',
      component: () => import('../views/TaggingView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Items',
      path: '/gear',
      component: () => import('../views/DataManage/Item/ItemsList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ItemCreate',
      path: '/gear/add',
      component: () => import('../views/DataManage/Item/ItemAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'ItemCreateBulk',
      path: '/gear/bulk-create',
      component: () => import('../views/DataManage/Item/ItemBulkAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'ItemDetail',
      path: '/gear/:id',
      component: () => import('../views/DataManage/Item/ItemDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Events',
      path: '/events',
      component: () => import('../views/DataManage/EventList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Locations',
      path: '/locations',
      component: () => import('../views/DataManage/Location/LocationList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'LocationCreate',
      path: '/locations/add',
      component: () => import('../views/DataManage/Location/LocationAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'LocationDetail',
      path: '/locations/:id',
      component: () => import('../views/DataManage/Location/LocationDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Sublocations',
      path: '/sublocations',
      component: () => import('../views/DataManage/Sublocation/SublocationList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'SublocationCreate',
      path: '/sublocations/add',
      component: () => import('../views/DataManage/Sublocation/SublocationAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'SublocationDetail',
      path: '/sublocations/:id',
      component: () => import('../views/DataManage/Sublocation/SublocationDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Templates',
      path: '/templates',
      component: () => import('../views/DataManage/Template/TemplateList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'TemplateCreate',
      path: '/templates/add',
      component: () => import('../views/DataManage/Template/TemplateAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'TemplateDetail',
      path: '/templates/:id',
      component: () => import('../views/DataManage/Template/TemplateDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Packages',
      path: '/packages',
      component: () => import('../views/DataManage/Package/PackageList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'PackageCreate',
      path: '/packages/add',
      component: () => import('../views/DataManage/Package/PackageAdd.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: true, layout: 'base' },
    },
    {
      name: 'PackageDetail',
      path: '/packages/:id',
      component: () => import('../views/DataManage/Package/PackageDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Gearcheck',
      path: '/gearcheck',
      component: () => import('../views/GearcheckView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'GearcheckReservation',
      path: '/gearcheck/:reservation',
      component: () => import('../views/GearcheckView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'InventoryChecks',
      path: '/gearchecks',
      component: () => import('../views/DataManage/Gearcheck/GearcheckList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'InventoryCheckDetail',
      path: '/gearchecks/:id',
      component: () => import('../views/DataManage/Gearcheck/GearcheckDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'InventoryCheckItems',
      path: '/gearcheck-items',
      component: () => import('../views/DataManage/GearcheckItem/GearcheckItemList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'InventoryCheckItemDetail',
      path: '/gearcheck-items/:id',
      component: () => import('../views/DataManage/GearcheckItem/GearcheckItemDetail.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'ItemHistory',
      path: '/item-history',
      component: () => import('../views/DataManage/ItemHistoryList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ItemDocuments',
      path: '/item-documents',
      component: () => import('../views/DataManage/ItemDocumentList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'ItemLogEntries',
      path: '/item-log-entries',
      component: () => import('../views/DataManage/ItemLogsList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'Logs',
      path: '/logs',
      component: () => import('../views/DataManage/LogsList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },
    {
      name: 'MailLogs',
      path: '/mail-logs',
      component: () => import('../views/DataManage/MailLogsList.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      name: 'ApplicationSettings',
      path: '/application-settings',
      component: () => import('../views/DataManage/ApplicationSettingsView.vue').catch(errorHandler),
      meta: { authRequired: true, adminOnly: false, layout: 'base' },
    },

    {
      path: '/:path(.*)',
      component: () => import('../views/NotFound.vue').catch(errorHandler),
      meta: { authRequired: false, adminOnly: false, layout: 'base' },
    },
  ],
})

router.beforeEach(authGuard)

export default router
