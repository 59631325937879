<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loader',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    classList: {
      type: String,
      default: 'w-6 h-6 border-4',
    },
  },
})
</script>

<template>
  <div class="absolute top-0 bottom-0 left-0 right-0 z-50 w-full transition-all duration-300" :class="[show ? 'opacity-100' : 'opacity-0']">
    <div v-if="show" class="flex items-center justify-center w-full h-full rounded bg-white/5">
      <div class="inline-block border-white rounded-full animate-spin border-t-transparent" :class="classList" role="status">
        <span class="hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
