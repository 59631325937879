<script setup lang="ts">
import { ref, watch } from 'vue'
import { toast } from 'vue3-toastify'

const props = withDefaults(
  defineProps<{
    loading: boolean
    fixed?: boolean
    useFilter?: boolean
    fullscreen?: boolean
  }>(),
  {
    fixed: false,
    useFilter: true,
    fullscreen: false,
  },
)

const forceStopLoading = ref(false)
const loaderExpiration = ref<number | null>(null)
watch(
  () => props.loading,
  (value) => {
    if (loaderExpiration.value) {
      clearTimeout(loaderExpiration.value)
    }
    if (value) {
      loaderExpiration.value = setTimeout(() => {
        if (props.loading) {
          forceStopLoading.value = true
          toast.warn('Something went wrong. Please try refresh your browser.')
        }
      }, 15000)
    }
  },
)
</script>

<template>
  <div
    class="relative duration-200 translate-all"
    :class="[loading && !forceStopLoading ? (fullscreen ? 'min-h-screen' : 'min-h-32') : '']"
    :style="useFilter && loading ? { filter: `grayscale(${loading ? 1 : 0})` } : {}"
  >
    <slot />
    <!-- Wrap the loading overlay in a transition -->
    <div name="fade-overlay" mode="out-in">
      <!-- Conditional rendering so that after fade out completes, it's removed -->
      <div
        v-if="loading && !forceStopLoading"
        class="inset-0 flex items-center justify-center"
        :class="{ absolute: !fixed, fixed: fixed }"
        style="background: rgba(5, 0, 18, 0.32); z-index: 10"
      >
        <div class="lds-dual-ring"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* The following classes control the transition states:
   - *-enter-* classes apply when the element is inserted
   - *-leave-* classes apply when the element is removed */
.fade-overlay-enter-active {
  /* No transition for enter, so it appears immediately */
  transition: none;
}
.fade-overlay-enter-from {
  opacity: 0;
}
.fade-overlay-enter-to {
  opacity: 1;
}

.fade-overlay-leave-active {
  transition: opacity 0.2s ease; /* Fade out duration */
}
.fade-overlay-leave-from {
  opacity: 1;
}
.fade-overlay-leave-to {
  opacity: 0;
}
</style>
