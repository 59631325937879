<template>
  <div class="relative flex items-start w-full mx-auto">
    <div class="flex items-center" :class="{ 'h-5': huge }">
      <input
        :id="formname"
        :ref="input"
        :disabled="disabled"
        :checked="modelValue"
        :name="formname"
        type="checkbox"
        class="w-4 h-4 transition-all border-gray-300 rounded cursor-pointer select-none text-zinc-500 focus:ring-secondary bg-origin-border hover:shadow-inset-button-hover"
        :class="{
          'shadow-button': modelValue,
          'cursor-not-allowed opacity-60': disabled,
          'w-6 h-6 text-2xl': huge,
        }"
        @change="sendClick"
      />
      <div v-if="hasSlot()" v-tooltip="tooltip !== '' ? tooltip : null" class="ml-2 cursor-pointer" @click="sendClick">
        <slot></slot>
      </div>
    </div>
    <div class="ml-3 text-sm">
      <label :class="{ 'opacity-60': disabled, 'text-[1.1rem]': huge }" :for="formname" class="block -mt-0.5 font-medium text-white">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, useSlots, getCurrentInstance } from 'vue'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    formname?: string // Make formname optional
    label?: string
    disabled?: boolean
    tooltip?: string
    huge?: boolean
  }>(),
  {
    label: '',
    disabled: false,
    tooltip: '',
    huge: false,
  },
)

const instance = getCurrentInstance()

const formname = computed(() => {
  return props.formname ?? `checkbox-${instance?.uid}`
})

const emit = defineEmits(['update:modelValue'])

const input = ref<HTMLInputElement | null>(null)

const slots = useSlots()

function sendClick() {
  emit('update:modelValue', !props.modelValue)
}

function hasSlot(name = 'default') {
  return !!slots[name]
}
</script>

<style scoped>
[type='checkbox']:checked:focus {
  @apply ring-primary bg-primary;
}
[type='checkbox']:checked {
  @apply bg-primary;
}
[type='checkbox']:focus {
  @apply ring-0;
}
</style>
